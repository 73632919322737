<template>
  <div class="bg-background">
    <van-tabs v-model="active" sticky animated swipeable @change="onchange">
      <van-tab :title="item.categoryname" v-for="(item,index) in titles" :key="index">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div class="article-top"></div>
          <div
            class="article-list"
            v-for="(item,index) in list"
            :key="index"
            @click="todetail(item.id)"
          >
            <van-row type="flex" justify="space-between">
              <van-col class="news-title" span="14">{{item.title}}</van-col>
              <van-col span="8">
                <img class="news-image" :src="item.headimgurl" />
              </van-col>
            </van-row>
            <div class="news-author">
            <van-row>
              <van-col span="13">
                <van-row type="flex" justify="space-between">
                  <van-col>{{item.author}}</van-col>
                  <van-col>{{item.createtime}}</van-col>
                </van-row>
              </van-col>
            </van-row>
          </div>
          </div>
          <!-- <div>底部地基</div> -->
          <div class="wrap-bottom"></div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: [],
      list: [],
      active: 0,
      id: "",
      loading: false,
      finished: false,
      pageindex: 1,
    };
  },
  created() {
    this.getarticleclass(() => {
      this.id = this.titles[0].id;
      console.log(this.id);
      this.initialization();
    });
  },
  methods: {
    onchange() {
      console.log(1111111111);
      this.id = this.titles[this.active].id;
      this.initialization();
    },
    //获取文章分类
    getarticleclass(callback) {
      this.$api.article.getarticleclass().then((res) => {
        this.titles = res.data.data;
        callback();
      });
    },
    initialization() {
      this.list = [];
      this.pageindex = 1;
      this.loading = true; //下拉加载中
      this.finished = false; //下拉结束
      if (this.loading) {
        this.onLoad();
      }
    },
    onLoad() {
      this.getarticlelist();
      this.pageindex++;
      //this.loading = false;
    },
    //获取文章列表
    getarticlelist() {
      this.$api.article.getarticlelist(this.id, this.pageindex).then((res) => {
        if (res.data.data.list.length == 0) {
          this.finished = true;
        } else {
          this.list.push(...res.data.data.list);
          console.log(this.list);
        }
        this.loading = false;
      });
    },
    todetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.article {
  &-top {
    height: 0.6rem;
    width: 100%;
    background-color: $bg-default;
  }
  &-list {
    height: 5.4rem;
    margin-bottom: 0.6rem;
    background-color: $bg-white;
  }
}
.bg-background {
  background-color: $bg-default;
}
</style>